<template>
  <div
    style="display: block; position: relative"
    @click="ackNotification({ type: toWatches })"
  >
    <slot />
    <v-badge
      v-if="notificationCount > 0"
      bordered
      inline
      color="white"
      :content="notificationCount"
    />
  </div>
</template>

<script setup>
import { ref } from "vue";
import { set } from "@vueuse/core";
import soundAlert from "../assets/sounds/sv-3.wav";
import { Howl, Howler } from "howler";
import Pusher from "pusher-js";

const props = defineProps({
  channel_name: {
    type: String,
    default: "agent",
  },
  event_name: {
    type: String,
    default: "cash-request",
  },
  type_name: {
    type: String,
    default: "cash-request",
  },
});
var sound = new Howl({
  src: [soundAlert],
  loop: true,
});
const userStore = useUserStore();
const config = useRuntimeConfig();
const base_api = config.public.apiUri;
const notificationCount = ref(0);
const network = ref();
const getNetwork = async () => {
  const { data, error, status } = await useFetch(`${base_api}/agent/network`, {
    key: "getNetwork",
    server: true,
    headers: { Authorization: `Bearer ${userStore.token}` },
    method: "get",
  });
  if (status.value == "success") {
    set(network, data.value);
  } else {
    set(alertModal, true);
    set(alertText, error.value.data.error);
  }
};
const getCashNoti = async () => {
  var role = userStore.user.role;
  var agent_id;
  var chanelName;
  if (props.channel_name == "agent") {
    if (role == "agent") {
      chanelName = `agent-${userStore.user._id}`;
    } else {
      await getNetwork();
      chanelName = `agent-${network.value.agent_id}`;
    }
  } else {
    var chanelName = "admin";
  }

  var pusher = new Pusher(config.public.pusherAppKey, {
    cluster: config.public.pusherAppCluster,
  });

  var channel = pusher.subscribe(chanelName);
  channel.bind(props.event_name, (data) => {
    if (props.type_name != "menu") {
      sound.play();
    }
    set(notificationCount, notificationCount.value + 1);
  });
};

getCashNoti();
const ackNotification = () => {
  //console.log(Object.keys(howlerList));

  set(notificationCount, 0);
  sound.stop();
};
</script>
